<template>
  <div>
    <aad-title>CCTF & Affiliate Events</aad-title>
    <p>Information current as of {{(new Date()).toLocaleDateString('en-US')}}. May be subject to change.</p>
    <aad-loader
      v-if="
        allCCTFAffiliateEvents == undefined ||
        allCCTFAffiliateEvents.length == 0
      "
    ></aad-loader>
    <div v-else>
      <div v-for="event in allCCTFAffiliateEvents" :key="event.id">
        <div class="meeting-app-info-block w-100">
          <div class="meeting-app-info-header row">
            <h4>{{ event.Name || "TBD" }}</h4>
          </div>

          <div class="meeting-app-info-body">
            <div class="row">
              <p class="col-12">
                <aad-icon
                  name="calendar"
                  class="meeting-app-info-svg mr-4"
                ></aad-icon>
                <span class="d-inline-flex flex-column">
                  <span>
                    <session-time-string
                      :session="event"
                      type="full-range"
                    ></session-time-string>
                  </span>
                </span>
              </p>
              <p class="col-12">
                <aad-icon
                  name="place"
                  class="meeting-app-info-svg mr-4"
                ></aad-icon>
                <span v-if="!event.Room"> Location To Be Announced</span>
                <span v-else class="d-inline-flex flex-column">
                  <span>{{ event.Space }}</span>
                  <span> {{ event.Room || "Location To Be Announced" }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SessionTimeString from "@/components/SessionTimeString.vue";

export default {
  mounted() {
    this.getCCTFAffiliateEvents();
  },
  components: { SessionTimeString },
  methods: {
    getCCTFAffiliateEvents() {
      this.$store.dispatch("loadCCTFAffiliateEvents");
    },
  },
  computed: {
    ...mapGetters(["allCCTFAffiliateEvents"]),
  },
};
</script>
<style scoped>
.meeting-app-info-body p {
  margin: 10px 0px;
}
</style>
